<template>
  <div class="bg-[#143566] w-full ">
    <div class="hidden lg:flex items-center justify-center align-center lg:space-x-0   pt-[35px] ">
      <a class="" href="#HOME">
        <img class="h-[50x]" src="../assets/SLLogo_pc.png"/>
      </a>
      <a href="https://www.jobstreet.com.ph/en/companies/161252493143697-solid-leisure-solutions-inc" target="_blank">
        <img class="h-[50px]" src="../assets/footer/Jobstreet.png"/>
      </a>
      <a href="https://ph.indeed.com/cmp/Solid-Leisure" target="_blank">
        <img class="h-[50px]" src="../assets/footer/Indeed.png"/>
      </a>
      <a href="https://www.linkedin.com/company/solidleisure-solutions/" target="_blank">
        <img class="h-[50px]" src="../assets/footer/LinkedIn.png"/>
      </a>
      <a href="https://www.facebook.com/SolidLeisureSolutionsInc.Official" target="_blank">
        <img class="h-[50px]" src="../assets/footer/facebook.png"/>
      </a>
      <a href="https://www.cakeresume.com/companies/solidleisure-solutions-inc?locale=zh-TW" target="_blank">
        <img class="h-[50px]" src="../assets/footer/CakeResume.png"/>
      </a>
    </div>
    <div class="lg:block hidden text-[#3D567D] text-base font-normal text-center py-[35px]">Copyright © 2022 - {{ new Date().getFullYear() }}
      SOLIDLEISURE SOLUTIONS INC. All rights reserved.
    </div>


    <div class="lg:hidden pt-[25px] w-[95%] mx-auto">
      <div class="flex flex-wrap justify-center">
        <a href="#HOME" class=" w-[90px] content-center self-center">
          <img src="../assets/footer/sl_h5.png"/>
        </a>
        <div class="mx-3 w-[90px] content-center self-center">
          <a href="https://www.jobstreet.com.ph/en/companies/161252493143697-solid-leisure-solutions-inc"
             target="_blank">
            <img src="../assets/footer/jobstreet_h5.png"/>
          </a>
        </div>
        <div class=" w-[90px] content-center self-center">
          <a href="https://ph.indeed.com/cmp/Solid-Leisure" target="_blank">
            <img class="" src="../assets/footer/Indeed_h5.png"/>
          </a>
        </div>
        <div class="py-2 mx-2 w-[90px] content-center self-center">
          <a href="https://www.linkedin.com/company/solidleisure-solutions/" target="_blank"
             class="flex justify-center">
            <img src="../assets/footer/LinkedIn_h5.png"/>
          </a>
        </div>
        <div class="my-2 mx-2 w-[90px] content-center self-center">
          <a href="https://www.facebook.com/SolidLeisureSolutionsInc.Official" target="_blank" class="flex justify-center">
            <img src="../assets/footer/facebook.png"/>
          </a>
        </div>
        <div class="my-2 mx-2 w-[90px] content-center self-center">
          <a href="https://www.cakeresume.com/companies/solidleisure-solutions-inc?locale=zh-TW" target="_blank" class="flex justify-center">
            <img src="../assets/footer/CakeResume_h5.png"/>
          </a>
        </div>
      </div>
      <div class="text-[#3D567D] lg:text-xl text-xs font-normal text-center py-[20px]">Copyright © 2022 - {{ new Date().getFullYear() }} SOLIDLEISURE
        SOLUTIONS INC. <br/>All rights reserved.
      </div>
    </div>

  </div>
</template>
<script>
// import SLLogo from "../assets/SLLogo.png"
export default {
  name: "FooterPart",
  // data: function () {
  //   return {
  //     SLLogo: SLLogo
  //   }
  // }
};
</script>
