<template>
  <div>
    <NavBar/>
    <LandingPage/>
    <AboutUs/>
    <ServicesPage/>
    <WhoWeNeed/>
    <PhotoGallery/>
    <ContactUs/>
    <FooterPart/>
  </div>

</template>

<script>
import NavBar from "./views/NavBar.vue";
import LandingPage from "./views/Landing.vue";
import AboutUs from "./views/AboutUs.vue";
import ServicesPage from "./views/Services.vue";
import WhoWeNeed from "./views/WhoWeNeed.vue";
import PhotoGallery from "./views/Gallery.vue";
import ContactUs from "./views/ContactUs.vue";
import FooterPart from "./views/Footer.vue";

require("vue2-animate/dist/vue2-animate.min.css");
export default {
  name: "App",
  components: {
    NavBar,
    LandingPage,
    AboutUs,
    ServicesPage,
    WhoWeNeed,
    PhotoGallery,
    ContactUs,
    FooterPart
  },
};
</script>

<style>
@import "./style.css";

/* #app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
} */
html, body {
  scroll-behavior: smooth !important;
}
</style>
