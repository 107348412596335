<template>

  <!--  <div class="flex relative overflow-hidden ">-->
  <!--    v-if="firstButton"-->

  <!--    v-if="secondButton"-->
  <!--      <img  :class="secondButton ? 'slide' : 'slideInactive' " class=" h-[calc(100vh-100px)] w-full" :src="secondImage"/>-->
  <!--    v-if="thirdButton"-->
  <!--      <img  :class="thirdButton ? 'slide' : 'slideInactive' " class=" h-[calc(100vh-100px)] w-full" :src="thirdImage"/>-->
  <section id="HOME">


    <hooper class="mt-[90px] w-full md:h-[calc(100vh-90px)] h-auto bg-black" :settings="hooperSettings">
      <slide v-for="(item, index) in landingComponents" v-bind:key="index">
        <div class="flex relative">
          <img class="md:block hidden md:h-[calc(100vh-90px)] w-full " :src="item.imgSrcPC"/>
          <img class="md:hidden w-full" :src="item.imgSrcH5"/>
          <div class="md:h-screen md:pt-0 md:top-0 sm:top-[45%] xs:top-[35%] top-[20%] absolute flex">
            <div
                class="my-auto pb-[60px]  w-[90%] mx-auto md:space-y-[2vh] space-y-8 md:text-7xl text-3xl md:text-start text-center md:font-normal font-normal">
              <div class="text-white italic">{{ item.textContent }}</div>
              <!--              md:leading-[76px] leading-8 md:font-[HelveticaNeueLt] font-[HelveticaNeueLt]  md:tracking-[2px] tracking-[2px]-->
              <div>
                <!--                <a class="tracking-widest" href="#ABOUTUS">-->
                <div
                    @click.prevent="aboutUsButton"
                    class="md:w-[190px] w-[120px] md:mx-0 mx-auto border-[2px] text-white font-normal border-white bg-transparent text-white rounded-full md:px-10 px-6 md:py-4 py-2 md:text-2xl text-base transition duration-500 ease select-none md:hover:bg-white/[.5] outline-none active:bg-white active:text-[#D3D3D3] z-[9909]"
                >
                  <!--                  md:focus:text-[#D3D3D3] md:focus:bg-white-->
                  <!--                  md:font-[HelveticaNeueMed] font-[HelveticaNeueLt]-->

                  About Us

                </div>
                <!--                </a>-->
              </div>
            </div>
          </div>
        </div>
      </slide>
      <hooper-pagination slot="hooper-addons"></hooper-pagination>
    </hooper>


    <!--    <carousel-->
    <!--        :centerMode="true"-->
    <!--        :perPage="1"-->
    <!--        paginationPosition="bottom-overlay"-->
    <!--        :autoplayHoverPause="false"-->
    <!--        :autoplay="true"-->
    <!--        :autoplayTimeout="2000"-->
    <!--        :loop="true"-->
    <!--        easing="ease-out"-->
    <!--        class="mt-[90px] w-full bg-black"-->
    <!--    >-->

    <!--      <slide v-for="(item, index) in landingComponents" v-bind:key="index">-->
    <!--        <div class="flex relative">-->
    <!--          <img class="md:block hidden md:h-[calc(100vh-90px)] w-full " :src="item.imgSrcPC"/>-->
    <!--          <img class="md:hidden w-full" :src="item.imgSrcH5"/>-->
    <!--          <div class="md:h-screen md:pt-0 md:top-0 sm:top-[45%] xs:top-[35%] top-[20%] absolute flex">-->
    <!--            <div class="my-auto pb-[60px]  w-[90%] mx-auto md:space-y-[2vh] space-y-8 md:text-7xl text-3xl md:text-start text-center md:font-normal font-normal">-->
    <!--                <div class="text-white italic">{{item.textContent}}</div>-->
    <!--              <div>-->
    <!--                <button-->
    <!--                    @click="aboutUsButton"-->
    <!--                    type="button"-->
    <!--                    class=" border-[2px] font-normal border-white bg-transparent text-white rounded-full md:px-10 px-6 md:py-4 py-2 md:text-2xl text-base transition duration-500 ease select-none md:hover:bg-white/[.5] outline-none active:bg-white active:text-[#D3D3D3] z-[9909]"-->
    <!--                >-->
    <!--                  About Us-->
    <!--                </button>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </slide>-->
    <!--    </carousel>-->
  </section>
</template>

<script>
import FirstImage from "../assets/landing_page/pc_banner_photo.png"
import SecondImage from "../assets/landing_page/pc_banner_photo-1.png"
import ThirdImage from "../assets/landing_page/pc_banner_photo-2.png"

import FirstImageH5 from "../assets/landing_page/h5_banner_photo.png"
import SecondImageH5 from "../assets/landing_page/h5_banner_photo-1.png"
import ThirdImageH5 from "../assets/landing_page/h5_banner_photo-2.png"

import {Hooper, Slide, Pagination as HooperPagination,} from 'hooper';
import 'hooper/dist/hooper.css';


export default {
  name: "LandingPage",
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
  data: function () {
    return {

      // :autoPlay="true" :play-speed="1000" :touchDrag="false" :hoverPause="false" :centerMode="true" :wheelControl="false"

      hooperSettings: {
        autoPlay: true,
        playSpeed: 4500,
        touchDrag: true,
        hoverPause: false,
        centerMode: true,
        wheelControl: false,

      },
      firstButton: true,
      secondButton: false,
      thirdButton: false,
      // firstImage: FirstImage,
      // secondImage: SecondImage,
      // thirdImage: ThirdImage
      landingComponents: [
        {
          imgSrcPC: FirstImage,
          imgSrcH5: FirstImageH5,
          textContent: "Business Information Technology Services Providers"
        },
        {
          imgSrcPC: SecondImage,
          imgSrcH5: SecondImageH5,
          textContent: "Outsourcing Services for Technical Support and Data Center Management"
        },
        {
          imgSrcPC: ThirdImage,
          imgSrcH5: ThirdImageH5,
          textContent: "Supplying Application Services and Software Development"
        },
      ]
    }
  },
  methods: {
    aboutUsButton() {
      let heightLanding = document.getElementById('HOME').clientHeight
      document.body.scrollTop = heightLanding;
      document.documentElement.scrollTop = heightLanding;
    }
  },


};
</script>
<style>
button.VueCarousel-dot {
  background-color: transparent !important;
  /*outline: 1px solid white !important;*/
  border: 1px solid white !important;
  padding: 0px !important;
  margin: 0px 5px 20px 5px !important;
  width: 15px !important;
  height: 15px !important;
  border-radius: 100% !important;
  -webkit-border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  outline-radius: 100% !important;
  -webkit-outline-radius: 100% !important;
  -moz-outline-radius: 100% !important;


}

button.VueCarousel-dot--active {
  /*outline: none !important;*/
  border: 0px !important;
  background-color: #ffffff !important
}

/*.fontHelvetica {*/
/*  font-family: HelveticaNeueMed !important;*/
/*  letter-spacing: 0.10em !important;*/
/*  font-weight: 400 !important;*/
/*}*/

.slideInactive {
  transition: 0.6s ease;
  opacity: 0;
  z-index: 1;
  position: absolute;
}

.slide {
  opacity: 1;
}

#HOME {
  scroll-margin-top: 10em;
}


button.hooper-indicator {
  background-color: transparent !important;
  /*outline: 1px solid white !important;*/
  border: 1px solid white !important;
  padding: 0px !important;
  margin: 0px 5px 20px 5px !important;
  width: 15px !important;
  height: 15px !important;
  border-radius: 100% !important;
  -webkit-border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  outline-radius: 100% !important;
  -webkit-outline-radius: 100% !important;
  -moz-outline-radius: 100% !important;


}

button.hooper-indicator.is-active {
  /*outline: none !important;*/
  border: 0px !important;
  background-color: #ffffff !important
}
</style>
