<template>
  <section id="SERVICES" class="bg-white md:mb-[150px] mb-[20px]">
    <div class="">
      <img class="mx-auto md:block hidden mt-[80px] w-[40%]" src="../assets/services/servicesSection.png"/>
      <img class="mx-auto md:hidden py-[5vh] w-[285px]" src="../assets/services/h5services.png"/>
      <div class="md:text-2xl text-md md:text-start text-center w-[80%] mx-auto text-[#999999]">
        <!--        fontHelvetica-->
        SOLIDLEISURE SOLUTIONS INC. is committed to the development of online games and operating systems, and provides
        O2O solutions to create a perfect experience of virtual and real integration. From the user's point of view,
        obtain prerequisite along to the frontline product managers, the UI designers create the user interface for an
        app, website, or other interactive media. Then hand them over to engineers for the development, and finally the
        test engineers will conduct testing for functional, performance, security and other debugging. After continuous
        verification and finalizing, we ensure the best experience for customers and in various fields. We have masters
        the corresponding languages and frameworks, from the front-end Vue.js, HTML5, CSS, Webpack, Electron, to the
        back-end NodeJS, Java (SSH), C++, GO, and App Android ( Java/Kotlin)- iOS (Swift), even architecture design or
        algorithm.
        We hire and compose of first-class talents to create software products.
      </div>
    </div>
  </section>
</template>
<script>
// import SLLogo from "../assets/SLLogo.png"
export default {
  name: "ServicesPage",
  // data: function () {
  //   return {
  //     SLLogo: SLLogo
  //   }
  // }
};
</script>
<style scoped>
.fontHelvetica {
  font-family: HelveticaNeueLt !important;
  letter-spacing: 0.01em !important;
  line-height: 30px;
}

#SERVICES {
  scroll-margin-top: 10em;
}

.animate {
  transition-delay: .4s;
  transition-duration: 2s;
  transition-timing-function: ease
}

.slide-right {
  /*transform: translateY(-100px)*/
  opacity: 0;
  transform: translateX(-50px);
}

.slide-right.animate-active {
  /*transform: translateY(0)*/
  opacity: 1;
  transform: translateX(0);
}

.slide-left {
  /*transform: translateY(-100px)*/
  opacity: 0;
  transform: translateX(50px);
}

.slide-left.animate-active {
  /*transform: translateY(0)*/
  opacity: 1;
  transform: translateX(0);
}


</style>
